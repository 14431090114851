import { IsBoolean, IsEmail, IsNumber, IsOptional, IsString } from "class-validator";
import { Expose } from "class-transformer";

export class UpdateCampDto {

  @IsString()
  @Expose()
  @IsOptional()
  name?: string;

  @IsString()
  @IsOptional()
  @Expose()
  deliveryNote?: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  street?: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  streetNumber?: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  postCode?: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  city?: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  subLocality?: string | null = null;

  @IsString()
  @IsOptional()
  @Expose()
  country?: string | null = null;

  @IsEmail()
  @IsOptional()
  @Expose()
  email?: string | null;

  @IsBoolean()
  @IsOptional()
  @Expose()
  isZea?: boolean;

  @IsBoolean()
  @IsOptional()
  @Expose()
  isWinter?: boolean;

  @IsBoolean()
  @IsOptional()
  @Expose()
  isExport?: boolean;
  
  @IsString()
  @IsOptional()
  @Expose()
  notes?: string;

  @IsBoolean()
  @Expose()
  @IsOptional()
  isArchived?: boolean;
}
