import { Exclude, Expose, Type } from "class-transformer";
import { ArticleFullSizeDto } from "./article.full-size.dto";
import { PalletFullSizeDto } from "./pallet.full-size.dto";
import { SubArticleFullSizeDto } from "./sub-article.full-size.dto";
import { IsBoolean, IsDateString, IsNumber, IsObject, IsOptional, ValidateNested } from "class-validator";

export class BoxFullSizeDto {
  @IsNumber()
  @Expose()
  id: number = 0;

  @IsDateString()
  @Exclude()
  createdAt: Date;

  @IsDateString()
  @Exclude()
  updatedAt: Date;

  @IsNumber()
  @IsOptional()
  @Expose()
  weight: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkArticle: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkSubArticle: number | null = null;

  @IsNumber()
  @Expose()
  articleCount: number = 0;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkPallet: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  fkPart: number | null = null;

  @IsBoolean()
  @Expose()
  newGoods: boolean = false;

  @IsDateString()
  @IsOptional()
  @Expose()
  created: Date = new Date();

  @IsNumber()
  @IsOptional()
  @Expose()
  fkCreatedAt: number | null = null;

  @IsNumber()
  @IsOptional()
  @Expose()
  createdBy: number | null = null;

  @IsDateString()
  @IsOptional()
  @Expose()
  lastInventury: Date | null = new Date();

  @IsDateString()
  @IsOptional()
  @Expose()
  movedByInventory: Date | null = new Date();

  @IsObject()
  @Type(() => PalletFullSizeDto)
  @IsOptional()
  @Expose()
  pallet: PalletFullSizeDto | null = new PalletFullSizeDto();

  @IsObject()
  @Type(() => ArticleFullSizeDto)
  @IsOptional()
  @Expose()
  article: ArticleFullSizeDto = new ArticleFullSizeDto();

  @IsObject()
  @Type(() => SubArticleFullSizeDto)
  @IsOptional()
  @Expose()
  subArticle: SubArticleFullSizeDto | null = new SubArticleFullSizeDto();
}
