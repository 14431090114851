import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as es6printJS from 'print-js';
import { environment } from '../../../environments/environment';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { OrderFullSizeDto } from '../../../../../shared/dtos';
import { BoxService } from '../../services/box.service';
import { MenuService } from '../../services/menu.service';
import { OrderService } from '../../services/order.service';
import { PartService } from '../../services/part.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-box-details',
  templateUrl: './box-details.component.html',
  styleUrls: ['./box-details.component.scss'],
})
export class BoxDetailsComponent implements OnInit, OnChanges {
  @Input() box: BoxFullSizeDto = new BoxFullSizeDto();
  @Output() boxChange: EventEmitter<BoxFullSizeDto> = new EventEmitter();
  boxData: { key: string; val: string }[] = [];
  changeAmount: boolean = false;
  toNewPallet: boolean = false;
  changeWeight: boolean = false;
  toOtherPallet: boolean = false;
  action: string = '';
  printUrl: string = '';
  order: OrderFullSizeDto = new OrderFullSizeDto();

  constructor(
    private httpSrv: HttpClient,
    private boxSrv: BoxService,
    private menuSrv: MenuService,
    private partSrv: PartService,
    private orderSrv: OrderService
  ) {}

  onOpenBoxTab() {
    this.menuSrv.addTabAndOpen({
      text: `Karton ${this.box?.id}`,
      route: ['boxes', this.box?.id.toString()!],
      exact: true,
    });
  }

  onOpenPalletTab() {
    this.menuSrv.addTabAndOpen({
      text: `Palette ${this.box?.pallet?.id}`,
      route: ['pallets', this.box?.pallet?.id.toString()!],
      exact: true,
    });
  }

  onOpenOrderTab() {
    this.menuSrv.addTabAndOpen({
      text: `Bestellung ${this.order?.id}`,
      route: ['orders', this.order?.id.toString()!],
      exact: true,
    });
  }

  async onBoxPrint() {
    const printUrl = `${environment.apiRoot}box/${this.box?.id}.pdf`;
    const blob = await firstValueFrom(this.httpSrv.get(printUrl, {
      responseType: 'blob',
    }));
    es6printJS({
      printable: URL.createObjectURL(blob),
      type: 'pdf',
      documentTitle: `Karton ${this.box?.id}`,
    });
  }

  onOpenArticleTab() {
    this.menuSrv.addTabAndOpen({
      text: `${this.box?.article.nameDe}`,
      route: ['articles', this.box?.article?.id.toString()!],
      exact: true,
    });
  }

  onOpenHashtagRowTab() {
    this.menuSrv.addTabAndOpen({
      text: `Reihe ${this.box?.pallet?.hashtagRow?.block?.name}${this.box?.pallet?.hashtagRow?.row}`,
      route: [
        'hashtagRows',
        this.box?.pallet?.hashtagRow?.id.toString()!,
      ],
      exact: true,
    });
  }

  update() {
    if (this.box?.id) {
      this.boxSrv.findById(this.box.id).subscribe((box) => {
        this.box = box;
        this.boxChange.emit(this.box);
        this.printUrl = `${environment.apiRoot}box/${box.id}.pdf`;

        if (box.fkPart) {
          this.partSrv.findById(box.fkPart).subscribe((part) => {
            if (part.fkOrder) {
              this.orderSrv.findById(part.fkOrder).subscribe((order) => {
                this.order = order;
              });
            }
          });
        }

        if (this.box && this.box.created) {
          this.boxData = [
            {
              key: 'Registriert',
              val:
                this.box.created.toLocaleDateString('de-DE') +
                ', ' +
                this.box.created.toLocaleTimeString(),
            },
            {
              key: 'Gewicht',
              val:
                ((this.box?.weight ?? 0) / 1000).toString().replace('.', ',') +
                ' kg',
            },
            {
              key: 'Neuware',
              val: this.box?.newGoods ? 'Ja' : 'Nein',
            },
          ].filter((obj) => obj.val && obj.val.length > 0);
        }
      });
    }
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }
}
