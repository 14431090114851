import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import * as es6printJS from 'print-js';
import { environment } from '../../../environments/environment';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { PalletFullSizeDto } from '../../../../../shared/dtos';
import { MenuService } from '../../services/menu.service';
import { PalletService } from '../../services/pallet.service';

@Component({
  selector: 'app-pallet-details',
  templateUrl: './pallet-details.component.html',
  styleUrls: ['./pallet-details.component.scss'],
})
export class PalletDetailsComponent implements OnInit, OnChanges {
  @Input() pallet: PalletFullSizeDto = new PalletFullSizeDto();
  @Output() palletChange: EventEmitter<PalletFullSizeDto> = new EventEmitter();
  @Output() boxSelected: EventEmitter<BoxFullSizeDto> = new EventEmitter();
  chooseWarehouseEnabled: boolean = false;
  articleCount: number = 0;

  onBoxSelect(box: BoxFullSizeDto) {
    this.boxSelected.emit(box);
  }
  constructor(private palletSrv: PalletService, private menuSrv: MenuService) {}

  onPalletPrint() {
    const printUrl = `${environment.apiRoot}pallet/${this.pallet?.id}.pdf`;
    es6printJS(printUrl);
  }

  update() {
    if (this.pallet && this.pallet.id) {
      this.palletSrv.findById(this.pallet.id).subscribe((pallet) => {
        this.pallet = pallet;

        this.articleCount =
          this.pallet.boxes
            .map((box) => box.articleCount ?? 0)
            .reduce((acc, count) => acc + count, 0) ?? 0;
      });
    }
  }

  onOpenPalletTab() {
    this.menuSrv.addTabAndOpen({
      text: `Palette ${this.pallet?.id}`,
      route: ['pallets', this.pallet?.id.toString()!],
      exact: true,
    });
  }

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges() {
    this.update();
  }
}
