import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as es6printJS from 'print-js';
import { environment } from '../../../environments/environment';
import { ArticleFullSizeDto } from '../../../../../shared/dtos';
import { BoxFullSizeDto } from '../../../../../shared/dtos';
import { SubArticleFullSizeDto } from '../../../../../shared/dtos';
import { PersonFullSizeDto, CreateBoxDto } from '../../../../../shared/dtos';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';
import { BoxService } from '../../services/box.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-box-register-form',
  templateUrl: './box-register-form.component.html',
  styleUrls: ['./box-register-form.component.scss'],
})
export class BoxRegisterFormComponent implements OnInit, OnChanges {
  @Input() article: ArticleFullSizeDto = new ArticleFullSizeDto();
  @Input() subArticle: SubArticleFullSizeDto = new SubArticleFullSizeDto();
  newBox: CreateBoxDto = new CreateBoxDto();
  @Output() boxCreated: EventEmitter<BoxFullSizeDto> = new EventEmitter();

  constructor(
    private boxSrv: BoxService,
    private alertSrv: AlertService,
    private http: HttpClient,
    protected sanitizer: DomSanitizer,
    private authSrv: AuthService
  ) {}

  onToggleNewGoods() {
    this.newBox.newGoods = !this.newBox.newGoods;
  }

  onRegister(options?: { print?: boolean }) {
    if (this.newBox.articleCount && this.newBox.articleCount > 0) {
      this.boxSrv.create(this.newBox).subscribe(
        async (box) => {
          this.boxCreated.emit(box);
          this.newBox = new CreateBoxDto();

          if (options?.print) {
            const printUrl = `${environment.apiRoot}box/${box.id}.pdf`;
            const blob = await firstValueFrom(this.http.get(printUrl, {
              responseType: 'blob',
            }));
            es6printJS({
              printable: URL.createObjectURL(blob),
              type: 'pdf',
              documentTitle: `Karton ${box.id}`,
            });
          }
          this.assignBox();
          this.alertSrv.alert.next({
            text: `Karton ${box.id} erstellt.`,
            linkText: '',
          });
        },
        (err) => {
          this.alertSrv.alert.next({
            text: 'Karton konnte nicht erstellt werden, bitte später probieren',
            linkText: '',
          });
        }
      );
    } else {
      this.alertSrv.alert.next({
        text: 'Bitte Anzahl eingeben!',
        linkText: '',
      });
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

  assignBox() {
    this.newBox.fkArticle = this.article.id;
    this.newBox.fkSubArticle = this.subArticle.id;
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.assignBox();
  }
}
