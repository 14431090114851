<app-loader *ngIf="loading"></app-loader>
<div class="animate-fadeIn" *ngIf="!loading">
  <ul *ngIf="camps && camps.length > 0" class="flex flex-col relative z-0 overflow-x-hidden">
    <li [ngClass]="{
        'cursor-pointer hover:bg-gray-100': mode === 'select',
        'bg-gray-100': mode === 'select' && selectedCampId === camp.id,
        'flex items-center justify-between': mode !== 'select'
      }" *ngFor="let camp of camps">
      <a (click)="mode === 'select' && onCampSelect(camp)">
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between">
            <div class="flex gap-2 w-full items-center justify-between">
              <p class="text-md font-medium text-primary line-clamp-2">
                {{ camp.name }}
              </p>
              <button *ngIf="mode === 'select'" tooltip="in neuem Tab öffnen" (click)="onOpenCampTab(camp); $event.stopPropagation()">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-700" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between sm:space-x-4">
            <div class="sm:flex">
              <p class="flex flex-col text-sm">
                <span class="text-gray-800">{{ camp.street }} {{ camp.streetNumber }}</span>
                <span class="text-gray-800">{{ camp.postCode }} {{ camp.city }}</span>
                <span class="text-gray-500 line-clamp-1">{{ camp.notes }}</span>
              </p>
            </div>
            <div class="text-gray-400 text-xs font-bold self-end flex items-center gap-1" *ngIf="camp.isArchived">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                <path d="M3 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3Z" />
                <path fill-rule="evenodd" d="M3 6h10v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6Zm3 2.75A.75.75 0 0 1 6.75 8h2.5a.75.75 0 0 1 0 1.5h-2.5A.75.75 0 0 1 6 8.75Z" clip-rule="evenodd" />
              </svg>
              
              <span>archiviert</span>
            </div>
          </div>
        </div>
      </a>
      <div class="mr-6" *ngIf="mode === 'remove' || mode === 'add'">
        <button class="bg-gray-100 hover:bg-gray-200 rounded-full border border-gray-200 p-1" tooltip="Orga entfernen"
          *ngIf="mode === 'remove'" (click)="onCampSelect(camp)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 " fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 12H6" />
          </svg>
        </button>
        <button class="bg-gray-100 hover:bg-gray-200 rounded-full border border-gray-200 p-1" tooltip="Orga hinzufügen"
          *ngIf="mode === 'add'" (click)="onCampSelect(camp)">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
      </div>
    </li>
  </ul>
  <div *ngIf="!camps || camps.length === 0" class="flex justify-center items-center mt-16">
    keine Organisationen
  </div>
</div>
