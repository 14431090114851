import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AlertService} from 'src/app/services/alert.service';
import {CampFullSizeDto, CreateCampDto, UpdateCampDto} from '../../../../../shared/dtos';
import {CampService} from '../../services/camp.service';
import {TransformerWrapper} from '../../../../../shared/src/transformer';
import {firstValueFrom, Observable, of, Subscription} from 'rxjs';
import {GoogleMapsService} from '../../services/google-maps.service';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

/**
 * The component is responsible for creating and editing camps.
 *
 * @author Julius Behrendt
 * @author Torben Scharf
 */
@Component({
  selector: 'app-camp-edit',
  templateUrl: './camp-edit.component.html',
  styleUrls: ['./camp-edit.component.scss'],
})
export class CampEditComponent implements OnInit, OnDestroy {
  camp: CampFullSizeDto = new CampFullSizeDto();
  loading: boolean = false;
  routerSubscription: Subscription;
  suggestions: AutocompletePrediction[] = [];
  suggestionsLoading: boolean = false;
  skipAddressValidation: boolean = false;

  constructor(private campSrv: CampService, private route: ActivatedRoute, private router: Router, private alertSrv: AlertService, private ngZone: NgZone, private googleMapsSrv: GoogleMapsService,) {
  }

  /**
   * Is executed when the organisation field input changes.
   *
   * @param event the event for the input
   */
  onInputChange(event: any): void {
    const input = event.target.value;
    if (input.length > 2) {
      this.getPlaceSuggestions(input);
    } else {
      this.suggestions = [];
    }
  }

  /**
   * Searches for Google Maps autocomplete options.
   *
   * @param input the input being searched for
   */
  getPlaceSuggestions(input: string): void {
    this.suggestionsLoading = true;
    this.googleMapsSrv.getPlaceSuggestions(input).subscribe({
      next: (suggestions: any): void => {
        this.ngZone.run((): void => {
          this.suggestions = suggestions;
          this.suggestionsLoading = false;
        });
      }, error: (): void => {
        this.ngZone.run((): void => {
          this.suggestions = [];
          this.suggestionsLoading = false;
        });
      }
    });
  }

  /**
   * Selects a Google Maps suggestion and assigns the fields.
   *
   * @param suggestion the selected suggestion
   */
  selectSuggestion(suggestion: any): void {
    this.googleMapsSrv.getPlaceDetails(suggestion.place_id).subscribe((place: any): void => {
      this.ngZone.run((): void => {
        this.camp.name = place.name || '';
        this.camp.street = this.getAddressComponent(place, 'route');
        this.camp.streetNumber = this.getAddressComponent(place, 'street_number');
        this.camp.postCode = this.getAddressComponent(place, 'postal_code');
        this.camp.subLocality = this.getAddressComponent(place, 'sublocality');
        this.camp.city = this.getAddressComponent(place, 'locality');
        this.camp.country = this.getAddressComponent(place, 'country');
        this.suggestions = [];
      });
    });
  }

  /**
   * Gets a Google Maps address component.
   *
   * @param place the place for the component
   * @param type the type of the component
   * @return the value of the component
   */
  getAddressComponent(place: any, type: string): string {
    const component = place.address_components.find((c: any) => c.types.includes(type));
    return component ? component.long_name : '';
  }

  /**
   * checks whether the address is valid.
   *
   * @return the boolean (as Observable) whether it is valid
   */
  addressValid(): Observable<boolean> {
    const body: {} = {
      'address': {
        'postalCode': this.camp.postCode,
        'locality': this.camp.city,
        'sublocality': this.camp.subLocality,
        'addressLines': [this.camp.street, this.camp.streetNumber],
      }
    }
    if (!this.skipAddressValidation) {
      return this.googleMapsSrv.validateAddress(body);
    }
    return of(true);
  }

  /**
   * Validates the address and saves the camp if successful.
   */
  onSave(): void {
    this.addressValid().subscribe({
      next: (valid: boolean): void => {
        if (valid) {
          this.camp.id ? this.updateCamp() : this.createCamp();
        } else {
          this.alertSrv.alert.next({
            text: 'Etwas stimmt nicht mit der Adresse. Bitte prüfe die Felder.',
            linkText: '',
          });
        }
      }, error: (): void => {
        this.alertSrv.alert.next({
          text: 'Ein unbekannter Fehler ist bei der Adressvalidierung aufgetreten.',
          linkText: '',
        });
      }
    });
  }

  /**
   * Creates the camp with the entered data.
   */
  createCamp(): void {
    const createDto: CreateCampDto = TransformerWrapper.plainToInstanceExclude(CreateCampDto, this.camp);
    this.campSrv.create(createDto).subscribe({
      next: (camp: CampFullSizeDto): void => {
        this.camp = camp;
        this.alertSrv.alert.next({text: 'Die Unterkunft wurde gespeichert.', linkText: '',});
        this.campSrv.updateTrigger.next();
        this.router.navigate(['/camps', {outlets: {camp: [camp.id.toString()]}}]);
      }, error: (): void => {
        this.alertSrv.alert.next({text: 'Fehler beim Speichern der Unterkunft.', linkText: '',});
      }
    });
  }

  /**
   * Updates the camp with the entered data.
   */
  updateCamp(): void {
    const updateDto: UpdateCampDto = TransformerWrapper.plainToInstanceExclude(UpdateCampDto, this.camp);
    this.campSrv.update(this.camp.id, updateDto).subscribe({
      next: (camp: CampFullSizeDto): void => {
        this.camp = camp;
        this.alertSrv.alert.next({text: 'Die Unterkunft wurde gespeichert.', linkText: '',});
        this.campSrv.updateTrigger.next();
        this.router.navigate(['/camps', {outlets: {camp: [camp.id.toString()]}}]);
      }, error: (): void => {
        this.alertSrv.alert.next({text: 'Fehler beim Speichern der Unterkunft.', linkText: '',});
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.routerSubscription = this.route.params.subscribe(async (params: Params): Promise<void> => {
      if (params.campId) {
        this.loading = true;
        this.camp = await firstValueFrom(this.campSrv.findById(params.campId));
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }
}
