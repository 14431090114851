<app-loader *ngIf="loading"></app-loader>
<div class="h-full flex flex-col overflow-y-auto" *ngIf="!loading">
  <main *ngIf="camp" class="flex-1 flex flex-col h-full overflow-y-auto relative z-0 focus:outline-none xl:order-last">
    <article class="flex flex-col h-full overflow-y-auto">
      <!-- Profile header -->
      <div class="flex-shrink-0">
        <div class="px-4 sm:px-6 lg:px-8">
          <div class="mt-6 min-w-0 flex-1">
            <div class="mt-4 flex justify-between items-center gap-8">
              <div class="flex space-x-2 items-center">
                <h1 [tooltip]="camp.name" class="text-2xl font-bold text-gray-900 line-clamp-1 uppercase">
                  {{ camp.name }}
                </h1>
                <button tooltip="Organisation bearbeiten" [routerLink]="[
                    '/camps',
                    { outlets: { camp: ['edit', camp.id] } }
                  ]">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-600" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                  </svg>
                </button>
                <button *ngIf="!camp.isArchived" tooltip="Organisation archivieren" (click)="onArchiveCamp()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                  </svg>
                </button>
                <button *ngIf="camp.isArchived" tooltip="Aus dem Archiv entfernen" (click)="onUnarchiveCamp()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 text-gray-600">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                  </svg>                  
                </button>
                <button tooltip="In neuem Tab öffnen" (click)="onOpenCampTab()">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"/>
                  </svg>
                </button>
              </div>
            </div>
            <div *ngIf="camp.isArchived" class="text-gray-500 font-bold text-sm flex items-center gap-1">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4">
                <path d="M3 2a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3Z" />
                <path fill-rule="evenodd" d="M3 6h10v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6Zm3 2.75A.75.75 0 0 1 6.75 8h2.5a.75.75 0 0 1 0 1.5h-2.5A.75.75 0 0 1 6 8.75Z" clip-rule="evenodd" />
              </svg>
              <span>archiviert</span>
            </div>
            <div class="flex space-x-4 max-w-4xl mt-2">
              <div>
                <h3>Anschrift</h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-700">
                  {{ camp.street }} {{camp.streetNumber}}
                </p>
                <p class="mt-1 max-w-2xl text-sm text-gray-700">
                  {{ camp.postCode }} {{camp.city}}
                </p>
                <div *ngIf="camp.deliveryNote" class="mt-1 flex gap-1 items-center max-w-80">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="size-4 shrink-0 text-gray-400">
                    <path d="M2.908 2.067A.978.978 0 0 0 2 3.05V8h6V3.05a.978.978 0 0 0-.908-.983 32.481 32.481 0 0 0-4.184 0ZM12.919 4.722A.98.98 0 0 0 11.968 4H10a1 1 0 0 0-1 1v6.268A2 2 0 0 1 12 13h1a.977.977 0 0 0 .985-1 31.99 31.99 0 0 0-1.066-7.278Z" />
                    <path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM2 12V9h6v3a1 1 0 0 1-1 1 2 2 0 1 0-4 0 1 1 0 0 1-1-1Z" />
                    <path d="M6 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                  </svg>
                  <p [tooltip]="camp.deliveryNote" class="max-w-2xl text-sm text-gray-700 line-clamp-1 italic">
                    {{ camp.deliveryNote }}
                  </p>
                </div>
                
              </div>
              <div *ngIf="camp.email">
                <h3>E-Mail</h3>
                <a class="mt-1 max-w-2xl text-sm text-primary600 hover:text-gray-700" [href]="'mailto:'+camp.email">
                  {{ camp.email }}
                </a>
              </div>
              <div *ngIf="camp.notes">
                <h3>Bemerkungen</h3>
                <p class="mt-1 max-w-2xl text-sm text-gray-700 whitespace-pre-line">
                  {{ camp.notes }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Tabs -->
      <div class="mt-6 sm:mt-2 2xl:mt-5 flex-shrink-0">
        <div class="border-b border-gray-200">
          <div class="px-4 sm:px-6 lg:px-8">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <!-- Current: "border-blue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
              <a (click)="view = 'persons'" class="
                  cursor-pointer
                  text-gray-500
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'persons'
                }" aria-current="page">
                Ansprechpartner:innen
              </a>

              <a (click)="view = 'orders'" class="
                  cursor-pointer
                  text-gray-500
                  hover:text-gray-700
                  whitespace-nowrap
                  py-4
                  px-1
                  border-b-2
                  font-medium
                  text-sm
                " [ngClass]="{
                  'border-primary text-gray-900': view === 'orders'
                }">
                Bestellungen
              </a>
            </nav>
          </div>
        </div>
      </div>
      <div class="flex-grow flex flex-col h-full overflow-y-auto">
        <app-camp-persons class="flex flex-col h-full overflow-y-auto" *ngIf="view === 'persons'"
                          [(camp)]="camp"></app-camp-persons>
        <app-camp-orders class="flex flex-col h-full overflow-y-auto" *ngIf="view === 'orders' && camp.id"
                         [campId]="camp.id"></app-camp-orders>
      </div>
    </article>
  </main>
</div>
