import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CampFullSizeDto } from '../../../../../shared/dtos';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-camp-list',
  templateUrl: './camp-list.component.html',
  styleUrls: ['./camp-list.component.scss'],
})
export class CampListComponent implements OnInit {
  @Input() camps: CampFullSizeDto[] = [];
  @Input() selectedCampId: number;
  @Input() mode: string = 'select';
  @Output() campSelect: EventEmitter<CampFullSizeDto> = new EventEmitter();
  loading: boolean = false;

  constructor(private menuSrv: MenuService) {}

  onOpenCampTab(camp: CampFullSizeDto) {
    this.menuSrv.addTabAndOpen({
      text: `${camp.name}`,
      route: ['camps', camp?.id.toString()!],
      exact: true,
    });
  }

  onCampSelect(camp: CampFullSizeDto) {
    this.campSelect.emit(camp);
  }

  ngOnInit(): void {}
}
