import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { exhaustMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.authService.authContext?.token) {
      return next.handle(req);
    }
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.authContext?.token,
    });
    const modifiedReq = req.clone({
      headers,
    });
    return next.handle(modifiedReq);
  }
}
