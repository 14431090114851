import {
    IsArray,
    IsBoolean,
    IsDateString,
    IsEmail,
    IsNumber,
    IsObject,
    IsOptional,
    IsString,
    IsUUID,
    ValidateNested
} from "class-validator";
import {GroupedOrders} from "../GroupedOrders";
import {PersonFullSizeDto} from "../people/person.full-size.dto";
import {OrderFullSizeDto} from "./order.full-size.dto";
import {Exclude, Expose, Type} from "class-transformer";


export class CampFullSizeDto {

    @IsNumber()
    @Expose()
    id: number;

    @IsDateString()
    @Exclude()
    createdAt: Date;

    @IsDateString()
    @Exclude()
    updatedAt: Date;

    @IsNumber()
    @IsOptional()
    @Exclude()
    createdBy: number | null;

    @IsUUID()
    @Expose()
    @IsOptional()
    keycloakGroupId: string | null = null;

    @IsString()
    @Expose()
    name: string = '';

    @IsString()
    @IsOptional()
    @Expose()
    deliveryNote: string | null = null;

    @IsString()
    @IsOptional()
    @Expose()
    street: string | null = null;

    @IsString()
    @IsOptional()
    @Expose()
    streetNumber: string | null = null;

    @IsString()
    @IsOptional()
    @Expose()
    postCode: string | null = null;

    @IsString()
    @IsOptional()
    @Expose()
    city: string | null = null;

    @IsString()
    @IsOptional()
    @Expose()
    subLocality: string | null = null;

    @IsEmail()
    @IsOptional()
    @Expose()
    email: string | null;

    @IsString()
    @IsOptional()
    @Expose()
    country: string | null = null;

    @IsBoolean()
    @Expose()
    isZea: boolean = false;

    @IsBoolean()
    @Expose()
    isWinter: boolean = false;

    @IsBoolean()
    @Expose()
    isExport: boolean = false;

    @IsString()
    @IsOptional()
    @Expose()
    notes: string | null = null;

    @IsBoolean()
    @Expose()
    isArchived: boolean;

    @IsArray()
    @Type(() => OrderFullSizeDto)
    @ValidateNested({each: true})
    @Expose()
    orders: OrderFullSizeDto[] = [];

    @IsArray()
    @Type(() => PersonFullSizeDto)
    @ValidateNested({each: true})
    @Expose()
    persons: PersonFullSizeDto[] = [];

    @IsObject()
    @Expose()
    groupedOrders: GroupedOrders = {};
}
