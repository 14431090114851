import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

interface ReleaseNote {
  version: string;
  date: Dayjs;
  changes: Array<string>;
  features: Array<string>;
  bugfixes: Array<string>;
}

@Component({
  selector: 'app-release-notes',
  standalone: false,
  templateUrl: './release-notes.component.html',
  styleUrl: './release-notes.component.scss',
  animations: [
    trigger('zoom', [
      state(
        'open',
        style({
          opacity: 1,
          transform: 'scale(1)',
        }),
      ),
      state(
        'closed',
        style({
          transform: 'scale(0.8)',
          opacity: 0,
        }),
      ),
      transition('open => closed', [animate('.1s ease')]),
      transition('closed => open', [animate('.1s ease')]),
    ]),
    trigger('fade', [
      state(
        'open',
        style({
          opacity: 1,
        }),
      ),
      state(
        'closed',
        style({
          opacity: 0,
        }),
      ),
      transition('open => closed', [animate('.1s ease')]),
      transition('closed => open', [animate('.1s ease')]),
    ]),
  ],
})
export class ReleaseNotesComponent implements OnInit {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  isOpen: boolean = false;
  releaseNotes: Array<ReleaseNote> = [
    {
      version: '2.1.1',
      date: dayjs('2024-08-10', 'YYYY-MM-DD'),
      changes: [],
      features: [
        'Adressen von Orgas sind jetzt genauer.',
        'Orgas können jetzt archiviert werden.',
      ],
      bugfixes: [],
    },
    {
      version: '2.1.0',
      date: dayjs('2024-07-28', 'YYYY-MM-DD'),
      changes: ['Verbessung der Anmeldung'],
      features: [],
      bugfixes: [],
    },
    {
      version: '2.0.6',
      date: dayjs('2024-04-26', 'YYYY-MM-DD'),
      changes: ['Performanceverbesserung bei der Bestellungsdarstellung'],
      features: [],
      bugfixes: [
        'Suchen von Personen funktioniert unabhängig von Groß- und Kleinschreibung',
        'Löschen von Personen aktualisiert jetzt die Liste in Orgas direkt',
        'Sollte das Erstellen von Personen einen Fehler verursachen, wird dies nun angezeigt, anstatt eine Erfolgmeldung zu generieren.',
      ],
    },
    {
      version: '2.0.5',
      date: dayjs('2024-03-10', 'YYYY-MM-DD'),
      changes: ['Größere Aufräumarbeiten im Backend 🧹'],
      features: [
        'Bestellungen: Bestandteile von Bestellungen (Paletten, Gitterwagen etc.) können jetzt nachträglich geändert werden.',
        'Orgas: Personen können jetzt direkt über die Orga angelegt werden.',
      ],
      bugfixes: [
        'Sonderzeichen in QR-Codes werden jetzt korrekt erzeugt.',
        'Hinzufügen von Kartons mit fehlerhaften QR-Codes funktioniert dennoch.',
        'Orgas konnten u.U. nicht angelegt werden. Das wurde behoben.',
        'Bestellungen: Beim Austragen von Bestellungsteilen setzt sich die Abteilung nicht mehr zurück.',
      ],
    },
    {
      version: '2.0.4',
      date: dayjs('2024-02-23', 'YYYY-MM-DD'),
      changes: [],
      features: [],
      bugfixes: ['Große Umbauten im Backend 🏗️'],
    },
    {
      version: '2.0.3',
      date: dayjs('2024-01-16', 'YYYY-MM-DD'),
      changes: [
        'Bestellungen: Auf den Bestellungsdruckscheinen werden jetzt die Bestellungsnummern auf jeder Seite angezeigt.',
        'Bestellungen: Die Übersicht der Bestellungsteile wurde überarbeitet.',
        'Bestellungen: Eine Bestellung hat jetzt standardmäßig den Typ "Abholung".',
      ],
      features: [
        'Bestellungen: Es ist jetzt möglich, Bestellungsteile nach Abteilung zu filtern.',
      ],
      bugfixes: [
        'Bestellungen: Irreführende Bezeichungen von Buttons bei Statuswechsel geändert.',
        'Bestellungen: Hinweise wurden in der Übersicht nicht angezeigt.',
        'Bestellungen: Beim Ausdruck einer Bestellung wurden nicht die Unterartikel und damit nicht alle Größen angezeigt.',
        'Orgas: Die Bestellungen zu einer Orga werden jetzt korrekt angezeigt.',
        'Bestellungen: Leere Seiten beim Ausdruck von Bestellungen sind entfernt.',
      ],
    },
    {
      version: '2.0.2',
      date: dayjs('2024-01-08', 'YYYY-MM-DD'),
      changes: [
        'Bestellungen: Über einen neuen Button können Bestellungsteile direkt abgehakt werden.',
        'Lieferungskarte: Die Bestellungen können jetzt nach Status gefiltert werden.',
      ],
      features: [],
      bugfixes: [
        'Registrierung: Behebung eines Fehlers, bei dem Lagerplätze Kartons nicht korrekt zugeordert wurden.',
      ],
    },
    {
      version: '2.0.1',
      date: dayjs('2024-01-04', 'YYYY-MM-DD'),
      changes: [
        'Bestellungserstellung: Das Orga-Suchfeld wurde vergrößert.',
        'Bestellungserstellung: Im Orga-Suchfeld wird jetzt die Straße der Orga angezeigt.',
        'Bestellungserstellung: Im Orga-Suchfeld wird jetzt die E-Mail-Adresse einer Person angezeigt.',
      ],
      features: [],
      bugfixes: [
        'Bestellungserstellung: Suchfeld für Orgas taucht jetzt zuverlässiger auf.',
        'Bestellungserstellung: Beim Hinzufügen eines Artikels wird keine "0" mehr hinterlassen.',
        'Registrierung: Nach dem Löschen von Kartons wird list Liste jetzt aktualisiert.',
      ],
    },
    {
      version: '2.0.0',
      date: dayjs('2023-12-28', 'YYYY-MM-DD'),
      changes: [],
      features: ['Release des neuen Logistik-Systems 🚀'],
      bugfixes: [],
    },
    {
      version: '0.0.1',
      date: dayjs('2023-12-03', 'YYYY-MM-DD'),
      changes: ['Aktualisierung der unterliegenden Technologien'],
      features: ['Versionssystem eingeführt'],
      bugfixes: [],
    },
  ];

  onClose() {
    this.isOpen = false;
    setTimeout(() => {
      this.close.emit();
    }, 100); // sync with animation
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.isOpen = true;
    }, 100); // sync with animation
  }
}
